import React, { Fragment } from 'react'
import Markdown from 'react-markdown'

import { renderLink } from 'utils'

const renderList = (list) => (
  <ul key={list}>
    {list.map((item, i) => {
      if (typeof item === 'object') {
        if (Array.isArray(item)) return renderList(item)

        const { id, text } = item

        if (id && text) {
          return (
            <li id={id} key={i}>
              <Markdown children={text} components={renderLink} />
            </li>
          )
        }
      }

      return (
        <li key={i}>
          <Markdown children={item} components={renderLink} />
        </li>
      )
    })}
  </ul>
)

const renderParagraph = (p) =>
  p.map((p, i) => {
    if (typeof p === 'object') {
      if (Array.isArray(p)) return renderList(p)

      const { id, text } = p

      if (id && text) {
        return (
          <div id={id} key={i}>
            <Markdown children={text} components={renderLink} />
          </div>
        )
      }
    }

    return <Markdown key={i} children={p} components={renderLink} />
  })

export const renderSection = ({ id, title, p, list, info }) => {
  console.log(id, title, p, list, info)
  return (
    <section id={id}>
      <Markdown
        className="heading-h3"
        children={title}
        components={renderLink}
      />

      {p && renderParagraph(p)}

      {list && (
        <ol className="num-list">
          {list.map((item, i) => {
            if (typeof item === 'object') {
              const { title, list } = item

              return (
                <li key={i}>
                  <Markdown children={title} components={renderLink} />
                  {list && (
                    <ol className="alpha-list">
                      {list.map((item, i) => (
                        <li key={i}>
                          <Markdown children={item} components={renderLink} />
                        </li>
                      ))}
                    </ol>
                  )}
                </li>
              )
            }

            return (
              <li key={i}>
                <Markdown children={item} components={renderLink} />
              </li>
            )
          })}
        </ol>
      )}

      {info &&
        info.map(({ title, p }) => (
          <Fragment key={title}>
            <Markdown
              className="heading-h4"
              children={title}
              components={renderLink}
            />
            {p &&
              p.map((p, i) => (
                <Markdown key={i} children={p} components={renderLink} />
              ))}
          </Fragment>
        ))}
    </section>
  )
}

export const renderTermsSection = ({ id, title, p, list }) => {
  return (
    <li id={id}>
      <h2>{title}</h2>

      {p && renderParagraph(p)}

      {list && (
        <ul className="list">
          {list.map((item, i) => {
            return (
              <li key={i}>
                <Markdown children={item} components={renderLink} />
              </li>
            )
          })}
        </ul>
      )}
    </li>
  )
}
