import sitelinks from './sitelinks'
import sitedata from './sitedata'
import sbApiRequests from './sbApiRequests'

const config = {
  ...sitelinks,
  ...sitedata,
  ...sbApiRequests,
  homepage: {
    platformOrder: {
      ios: 1,
      android: 2,
      pc: 3,
    },
  },
}

export default config
