import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

gsap.registerPlugin(ScrollToPlugin)

export const fullPageScroll = (
  isMenuOpen,
  minWidth = 1280,
  minHeight = 756
) => {
  const sections = document.querySelectorAll('.fp-section')
  const secArr = Array.prototype.slice.call(sections)

  const num = secArr.length - 1
  let curr = 0
  let scrollLock = false
  let isScrollEnd = false

  const navUp = isScrollEvent => {
    if (curr === 0) return

    if (isScrollEnd && curr === num) {
      isScrollEnd = false
    } else {
      curr -= 1
    }

    pagination(isScrollEvent)
  }

  const navDown = isScrollEvent => {
    if (curr === num) {
      isScrollEnd = true
      return
    }

    curr += 1
    pagination(isScrollEvent)
  }

  const pagination = (isScrollEvent = true) => {
    scrollLock = true
    const offsetTop = secArr[curr].offsetTop - 60

    const timeout = isScrollEvent ? 1300 : 0

    gsap.to(window, {
      duration: 1,
      scrollTo: offsetTop,
      ease: 'power3',
    })

    window.setTimeout(() => {
      scrollLock = false
    }, timeout)
  }

  const onKeyDown = e => {
    if (scrollLock) return

    if (e.keyCode === 40) {
      navDown(false)
    }

    if (e.keyCode === 38) {
      navUp(false)
    }
  }

  const onScroll = e => {
    if (!isScrollEnd && curr <= num) {
      e.preventDefault()
    }

    if (scrollLock) return

    if (e.deltaY < 0) {
      navUp()
    } else {
      navDown()
    }
  }

  const onNavScroll = () => {
    secArr.map((section, i) => {
      const bounding = section.getBoundingClientRect()
      const { top, bottom } = bounding

      const winHeight =
        window.innerHeight || document.documentElement.clientHeight

      if (top >= 0 && bottom <= winHeight) {
        curr = i
      }

      return null
    })

    const scrollTop = window.pageYOffset
    const lastOffsetTop = secArr[num].offsetTop - 60

    if (scrollTop > lastOffsetTop) {
      isScrollEnd = true
      if (curr === 0) curr = num
    } else {
      isScrollEnd = false
    }
  }

  // Modern Chrome requires { passive: false } when adding event
  let supportsPassive = false
  try {
    window.addEventListener(
      'test',
      null,
      Object.defineProperty({}, 'passive', {
        get: function() {
          supportsPassive = true
          return ''
        },
      })
    )
  } catch (e) {}

  const wheelOpt = supportsPassive ? { passive: false } : false
  const wheelEvent =
    'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel'
  const isTouchDevice = 'ontouchstart' in document.documentElement

  const handleListeners = () => {
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth

    const height =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight

    if (
      !isMenuOpen &&
      !isTouchDevice &&
      width >= minWidth &&
      height >= minHeight
    ) {
      onNavScroll()
      window.addEventListener(wheelEvent, onScroll, wheelOpt)
      window.addEventListener('keydown', onKeyDown)
      window.addEventListener('scroll', onNavScroll)
    } else {
      window.removeEventListener(wheelEvent, onScroll, wheelOpt)
      window.removeEventListener('keydown', onKeyDown)
      window.removeEventListener('scroll', onNavScroll)
    }
  }

  const removeListeners = () => {
    window.removeEventListener(wheelEvent, onScroll, wheelOpt)
    window.removeEventListener('keydown', onKeyDown)
    window.removeEventListener('scroll', onNavScroll)
  }

  return {
    handleListeners,
    removeListeners,
  }
}
