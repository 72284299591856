import React from 'react'
import { HeaderProvider, SessionProvider } from 'cr-static-shared-components'

const wrapRoot = ({ element }) => {
  return (
    <SessionProvider>
      <HeaderProvider>{element}</HeaderProvider>
    </SessionProvider>
  )
}

export default wrapRoot
