import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import {
  initialiseSegment,
  OneTrustConsent,
  GTMContainer,
} from 'cr-static-shared-components'

import favicon from 'images/favicon.png'
import { languageList } from 'intl'
import Metadata from 'components/seo/metadata'
import { useSiteMetadata } from 'hooks/use-site-metadata'

const PageComponent = ({ pathname, element }) => {
  const {
    assetPrefix,
    siteMetadata: { baseUrl: url },
  } = useSiteMetadata()

  const path = pathname.replace(assetPrefix, '')
  let canonicalHref = `${url}${pathname}`

  const langList = !Array.isArray(languageList)
    ? Object.keys(languageList)
    : languageList
  const localized = langList.find((lang) => path.includes(`/${lang}/`))
  const isDefaultPage = localized ? false : true
  if (isDefaultPage) canonicalHref = `${url}${assetPrefix}${path}`

  return (
    <>
      <OneTrustConsent />

      {typeof window !== 'undefined' && initialiseSegment()}

      <Helmet>
        <link rel="icon" href={favicon} />
        <link rel="canonical" href={canonicalHref} />
        <meta property="og:url" content={canonicalHref} />

        <script
          src="https://www.googletagmanager.com/gtag/js?id=AW-458164384"
          async
        />

        <script>{`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-458164384');
        `}</script>

        <script>{`
            function gtag_report_conversion(url) { var callback = function () { if (typeof(url) != 'undefined') { window.location = url; } }; gtag('event', 'conversion', { 'send_to': 'AW-458164384/VEMjCITyzO0BEKCRvNoB', 'event_callback': callback }); return false; }
        `}</script>

        <script>{`
          window.twttr = (function(d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0],
              t = window.twttr || {};
            if (d.getElementById(id)) return t;
            js = d.createElement(s);
            js.id = id;
            js.src = "https://platform.twitter.com/widgets.js";
            fjs.parentNode.insertBefore(js, fjs);

            t._e = [];
            t.ready = function(f) {
              t._e.push(f);
            };

            return t;
          }(document, "script", "twitter-wjs"));
        `}</script>
      </Helmet>

      <GTMContainer />
      {isDefaultPage && <Metadata />}
      {element}
    </>
  )
}

PageComponent.propTypes = {
  pathname: PropTypes.string,
  element: PropTypes.node.isRequired,
}

PageComponent.defaultProps = {
  pathname: '',
}

export default PageComponent
