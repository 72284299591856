import siteData from 'data/config'
const { appsFlyerCustomParam } = siteData

export const onelink = ({ url }) => {
  const mediaSource = {
    keys: ['utm_source', 'pid'],
    defaultValue: 'Smart_Script_Landing',
  }

  const campaign = {
    keys: ['utm_campaign', 'c'],
    defaultValue: 'Default_Campaign',
  }

  const ad = { keys: ['utm_ad', 'af_ad'], defaultValue: 'Default_Ad' }

  const adSet = {
    keys: ['utm_adset', 'af_adset'],
    defaultValue: 'Default_Ad_Set',
  }

  const afSub1 = { keys: ['gclid', 'af_sub1'], defaultValue: 'Default_clickid' }

  const afSub2 = {
    keys: ['fbclid', 'af_sub2'],
    defaultValue: 'Default_clickid',
  }

  const googleClickIdKey = 'af_sub1'

  const platforms = {
    steam: {
      platformName: 'steam',
      appid: '001',
      redirectURL: url,
    },
    nativepc: {
      platformName: 'nativepc',
      appid: 'OPMWPC001',
      redirectURL: url,
    },
  }

  const afParameters = {
    mediaSource,
    campaign,
    adSet,
    ad,
    afSub1,
    afSub2,
    googleClickIdKey,
    afCustom: [
      {
        paramKey: 'keyword',
        keys: ['keyword'],
        defaultValue: 'Default_Keywords',
      },
      { paramKey: 'af_xplatform', keys: [], defaultValue: 'true' },
    ],
  }

  const clickUrls = Object.keys(platforms).reduce((acc, curr) => {
    const platform = platforms[curr]

    const result = window.AF_SMART_SCRIPT.generateDirectClickURL({
      afParameters,
      platform: platform.platformName,
      app_id: platform.appid,
      redirectURL: platform.redirectURL,
    })

    if (result) {
      return { ...acc, [curr]: result.clickURL }
    }

    return { ...acc }
  }, {})

  return clickUrls
}

export const onelinkCustomParam = () => {
  const appendParams = appsFlyerCustomParam
    .map(({ key, value }) => `${key}=${value}`)
    .join('&')

  return `&${appendParams}`
}
