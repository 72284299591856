import { useStaticQuery, graphql } from 'gatsby'

export const useSiteMetadata = () => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        assetPrefix
        siteMetadata {
          baseUrl
          title
          titleTemplate
          eventTitle
          description
          url
          banner
        }
      }
    }
  `)
  return site
}
