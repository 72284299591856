import clientRoutes from 'data/clientRoutes'

export const isClientPage = () => {
  if (typeof window !== 'undefined') {
    const { pathname } = window.location

    const findPath = clientRoutes.find(route => pathname.includes(route))

    return findPath ? true : false
  }
}

export const getClientPathPrefix = () => {
  if (typeof window !== 'undefined') {
    const { pathname } = window.location

    const pathPrefix = clientRoutes.find(route => pathname.includes(route))

    return pathPrefix
  }
}
