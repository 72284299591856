export const isDst = targetDate => {
  const hasDST = (date = new Date()) => {
    const jan = new Date(date.getFullYear(), 0, 1).getTimezoneOffset()
    const jul = new Date(date.getFullYear(), 6, 1).getTimezoneOffset()
    return Math.max(jan, jul) !== date.getTimezoneOffset()
  }

  const targetTimeIndex = targetDate.indexOf(' ')
  const targetDateOnly = targetDate.slice(0, targetTimeIndex)
  const dateParts = targetDateOnly.split('-')
  const targetYear = parseInt(dateParts[0], 10)
  const targetMonth = parseInt(dateParts[1], 10)
  const targetDay = parseInt(dateParts[2], 10)

  return hasDST(new Date(targetYear, targetMonth, targetDay))
}
