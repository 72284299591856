import React from 'react'
import { Link as GatsbyLink } from 'gatsby'

const Link = ({ children, to, activeClassName, partiallyActive, ...rest }) => {
  const internal = /^\/(?!\/)/.test(to)

  if (internal) {
    return (
      <GatsbyLink
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        onMouseDown={e => e.preventDefault()}
        {...rest}
      >
        {children}
      </GatsbyLink>
    )
  }
  return (
    <a href={to} {...rest} onMouseDown={e => e.preventDefault()}>
      {children}
    </a>
  )
}

export default Link
