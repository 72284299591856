import React from 'react'
import PropTypes from 'prop-types'
import { Link as GatsbyLink, navigate as gatsbyNavigate } from 'gatsby'
import {
  createLocaleSlug,
  createEnLocaleSlug,
  track,
} from 'cr-static-shared-components'

import { IntlContextConsumer } from './intl-context'
import intlOptions from '../config'
import { isClientPage, getClientPathPrefix } from 'utils'

const { pathPrefix, languages } = intlOptions

export const withPrefix = (url) => {
  const prefix = process.env.NODE_ENV === 'production' ? pathPrefix : ''
  return `${prefix}${url}`
}

const Link = ({ to, language, children, onClick, ...rest }) => (
  <IntlContextConsumer>
    {(intl) => {
      const languageLink = language || intl.language
      const link =
        intl.routed || language
          ? `/${languageLink}${pathPrefix}${to}`
          : withPrefix(to)

      const handleClick = (e) => {
        if (language) {
          localStorage.setItem('gatsby-intl-language', language)
        }
        if (onClick) {
          onClick(e)
        }
      }

      return (
        <GatsbyLink {...rest} to={link} onClick={handleClick}>
          {children}
        </GatsbyLink>
      )
    }}
  </IntlContextConsumer>
)

Link.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
  language: PropTypes.string,
}

Link.defaultProps = {
  to: '',
}

export default Link

export const navigate = (to, options) => {
  if (typeof window === 'undefined') {
    return null
  }

  const { language, routed } = window.___gatsbyIntl
  const link = routed ? `/${language}${pathPrefix}${to}` : withPrefix(to)
  gatsbyNavigate(link, options)
}

export const changeLocale = (language, to) => {
  if (typeof window === 'undefined') {
    return null
  }

  const isClientPath = isClientPage()

  if (isClientPath) {
    const clientPathPrefix = getClientPathPrefix()

    const removeEndSlash = (pathname) => {
      return pathname.endsWith('/') ? pathname.slice(0, -1) : pathname
    }

    const removeLocalePart = (pathname) => {
      const path = removeEndSlash(pathname)
      const i = path.indexOf(`/`, 1)

      if (path.startsWith(clientPathPrefix)) {
        return pathname.endsWith('/') ? `${path}/` : path
      } else {
        return pathname.endsWith('/')
          ? `${path.substring(i)}/`
          : path.substring(i)
      }
    }

    const removePathPrefix = (pathname) => pathname.replace(pathPrefix, '')

    const pathname =
      to || removeLocalePart(removePathPrefix(window.location.pathname))

    const link =
      language !== 'en'
        ? `/${language}${pathPrefix}${pathname}${window.location.search}`
        : `${pathPrefix}${pathname}${window.location.search}`

    localStorage.setItem('gatsby-intl-language', language)
    window.location.assign(link)

    return null
  } else {
    const { routed } = window.___gatsbyIntl
    const pathname = window.location.pathname
    const env = process.env.NODE_ENV

    const slug = createLocaleSlug({
      routed,
      isClientPage: false,
      pathname,
      env,
      pathPrefix,
      languages,
    })

    const enSlug = createEnLocaleSlug({
      slug,
      isClientPage: false,
      env,
      pathPrefix,
    })

    const link =
      language !== 'en'
        ? `/${language}${pathPrefix}${slug}${window.location.search}`
        : withPrefix(`${enSlug}${window.location.search}`)

    const oldLanguage = localStorage.getItem('gatsby-intl-language')

    const trackDeterminer = (urlKey) => {
      const urlParts = urlKey.split('/')
      const urlStrings = urlParts.filter((part) => part.length)
      const url = !urlStrings.length ? '/' : urlStrings.slice(-1)[0]

      switch (url) {
        case 'gamevault-application':
          return {
            locationName: 'Gamevault Developer Application Language Toggle',
            reqTrack: true,
          }
        case 'games':
        case '/':
          return { locationName: 'Games Landing Footer Toggle', reqTrack: true }
        default:
          return { locationName: null, reqTrack: false }
      }
    }

    const page = window.location
    const { locationName, reqTrack } = trackDeterminer(page && page.pathname)

    const trackDetails = {
      location: locationName,
      textOfButtonOrLink: 'Language Toggle',
      oldValue: oldLanguage,
      newValue: language,
      pageTitle: document.title,
      eventSource: 'cr_games_all',
    }

    localStorage.setItem('gatsby-intl-language', language)
    window.location.assign(link)

    if (typeof window !== undefined && reqTrack) {
      track('CTA Clicked', trackDetails, false)
    }

    return null
  }
}
