import axios from 'axios'
import data from 'data/config'
import { config } from 'cr-static-shared-components'

const { newsApiUrl } = config
const { newsApi } = data

const createInstance = token => {
  const NewsApiInstance = axios.create({
    baseURL: newsApiUrl,
  })

  NewsApiInstance.interceptors.request.use(config => {
    config.headers.Authorization = `Bearer ${token}`
    return config
  })

  return NewsApiInstance
}

const getNewsCategory = async (token, locale) => {
  const { params, categoryEndpoint } = newsApi
  const NewsInstanceWToken = createInstance(token)

  try {
    const response = await NewsInstanceWToken.get(
      `${locale}${categoryEndpoint}`,
      {
        ...params,
      }
    )
    return response
  } catch (error) {
    console.error(error)
  }
}

const getNewsList = async (token, locale, title) => {
  const {
    params: { pageSize, page },
    listEndpoint,
  } = newsApi
  const NewsInstanceWToken = createInstance(token)

  try {
    const response = await NewsInstanceWToken.get(
      `${locale}${listEndpoint}${title}&page_size=${pageSize}&page=${page}`
    )
    return response
  } catch (error) {
    console.error(error)
  }
}

export { getNewsCategory, getNewsList }
