export const priconTextShadow = color =>
  `0 0 3px ${color}, 0 0 3px ${color}, 0 0 3px ${color},
    0 0 3px ${color}, 0 0 3px ${color}, 0 0 2px ${color}, 0 0 2px ${color},
    0 0 2px ${color}, 0 0 2px ${color}, 0 0 2px ${color}`

export const mitraTextShadow = color =>
  `0 0 3px ${color}, 0 0 3px ${color}, 0 0 3px ${color},
    0 0 3px ${color}, 0 0 3px ${color}, 0 0 2px ${color}, 0 0 2px ${color},
    0 0 2px ${color}, 0 0 2px ${color}, 0 0 2px ${color}, 0 0 4px ${color}, 0 0 4px ${color}, 0 0 4px ${color}, 0 0 6px ${color}, 0 0 6px ${color}, 0 0 12px ${color}, 0 0 12px ${color}`

export const mitraTitleTextShadow = (stroke, inner, outer) =>
  `0 0 2px ${inner}, 0 0 2px ${inner}, 0 0 2px ${inner}, 0 0 3px ${inner}, 0 0 4px ${inner}, 0 0 4px ${stroke}, 0 0 4px ${stroke}, 0 0 4px ${stroke}, 0 0 6px ${outer}, 0 0 6px ${outer}`

export const mitraTextShadowSoft = color =>
  `0px 0px 10px ${color}, 1px 1px 10px ${color}, -1px -1px 10px ${color}, 0px 0px 16px ${color}`

export const mitraTextShadowChar = () =>
  `1px 1px 1px #f2eedb, -1px -1px 1px #f2eedb, 1px -1px 1px #f2eedb, -1px 1px 1px #f2eedb, 0 0 2px #f2eedb, 0 0 3px #f2eedb`

export const mitraTextShadowOutlineChar = () =>
  `1px 1px 1px #f2eedb, -1px -1px 1px #f2eedb, 1px -1px 1px #f2eedb, -1px 1px 1px #f2eedb, 0 0 2px #f2eedb, 0 0 3px #f2eedb, 1px 1px 3px rgba(0, 0, 0, 0.32)`
