export const setActiveHeroNav = (nav, isStickyNav, includeNavHeight) => {
  const headerHeight = document.querySelector('.header-placeholder')
    .clientHeight

  if (isStickyNav) {
    const scrollTop = window.pageYOffset
    const hero = document.getElementById('hero')

    if (!hero) return null

    const heroNav = document.querySelector('.hero-nav')
    const heroNavSocial = document.querySelector('.hero-nav-social')
    const heroAdditional = document.querySelector('.hero-nav-additional')

    const heroHeight = hero.getBoundingClientRect().height
    const heroNavHeight = heroNav ? heroNav.getBoundingClientRect().height : 0
    const offsetTop = heroHeight - heroNavHeight

    if (scrollTop >= offsetTop) {
      heroNav && heroNav.classList.add('fixed')
      heroNavSocial && heroNavSocial.classList.add('fixed')
      heroAdditional && heroAdditional.classList.add('fixed')
    } else {
      heroNav && heroNav.classList.remove('fixed')
      heroNavSocial && heroNavSocial.classList.remove('fixed')
      heroAdditional && heroAdditional.classList.remove('fixed')
    }
  }

  nav
    .filter(({ isDisplayed }) => isDisplayed)
    .map(({ anchorId }) => {
      const heroNav = document.querySelector('.hero-nav')
      const heroNavHeight = heroNav ? heroNav.clientHeight : 0

      /* Get element bounding rectangle */
      const elId = anchorId ? anchorId : 'hero'
      const el = document.getElementById(elId)
      if (!el) return null

      const elRect = el.getBoundingClientRect()
      const elTop = includeNavHeight
        ? elRect.top - headerHeight - 1 - heroNavHeight
        : elRect.top - headerHeight - 1
      const elBottom = includeNavHeight
        ? elRect.bottom - headerHeight - 1 - heroNavHeight
        : elRect.bottom - headerHeight - 1

      /* Get nav link */
      const navLink = document.querySelector(`.hero-nav > .nav-item-${elId}`)
      if (elTop <= 0 && elBottom > 0) {
        navLink && navLink.classList.add('active')
      } else {
        navLink && navLink.classList.remove('active')
      }

      return null
    })
}

export const setActiveSectionNav = (nav, navClassName) => {
  const menuEl = navClassName ? `.${navClassName}` : '.menu-links'

  nav
    .filter(({ isDisplayed }) => isDisplayed)
    .map(({ anchorId }) => {
      /* Get element bounding rectangle */
      const elId = anchorId ? anchorId : 'hero'
      const el = document.getElementById(elId)
      if (!el) return null

      const elRect = el.getBoundingClientRect()
      const elTop = elRect.top - 1
      const elBottom = elRect.bottom - 1

      /* Get nav link */
      const navLink = document.querySelectorAll(`${menuEl} > .${elId}`)
      const navLinkArr = Array.from(navLink)

      navLinkArr.map(link => {
        if (elTop <= 0 && elBottom > 0) {
          link.classList.add('active')
        } else {
          link.classList.remove('active')
        }

        return null
      })

      return null
    })
}
