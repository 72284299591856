module.exports = {
  assetPrefix: `/games`,
  trailingSlash: `ignore`,
  siteMetadata: {
    title: `Anime Games for Android & iOS`,
    titleTemplate: `%s – Crunchyroll Games`,
    eventTitle: `CR Games Home`,
    description: `Play our fun and free mobile games on Android and iOS devices based on popular anime including Princess Connect! Re: Dive, Grand Summoners, Mass for the Dead, NARUTO x BORUTO NINJA TRIBES, and more!`,
    url: `https://crunchyroll.com/games`,
    baseUrl: `https://crunchyroll.com`,
    banner: `https://crunchyroll.com/games/default-banner.jpg`,
  },
  plugins: [
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `data`,
        path: `${__dirname}/src/data`,
      },
    },
    `gatsby-transformer-json`,
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/images`,
      },
    },
    `gatsby-plugin-emotion`,
    `gatsby-plugin-react-helmet`,
    `gatsby-transformer-sharp`,
    {
      resolve: `gatsby-plugin-sharp`,
      options: {
        defaults: {
          formats: [`auto`, `webp`],
          quality: 80,
        },
      },
    },
    `gatsby-plugin-image`,
  ],
}
