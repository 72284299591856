export const onNavLinkClick = (anchor, includeNavHeight) => {
  if (!anchor) {
    return window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const el = document.getElementById(anchor)
  if (!el) return

  /* Get header heights */
  const headerHeight = document.querySelector('.header-placeholder')
    .clientHeight
  const navHeight =
    document.querySelector('.hero-nav') &&
    document.querySelector('.hero-nav').clientHeight

  /* Get element bounding rectangle */
  const bodyTop = document.body.getBoundingClientRect().top
  const elTop = el.getBoundingClientRect().top
  const elOffset = includeNavHeight
    ? elTop - bodyTop - headerHeight - navHeight
    : elTop - bodyTop - headerHeight

  window.scrollTo({
    top: elOffset,
    behavior: 'smooth',
  })
}
