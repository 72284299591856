export const createDate = (dateString, localeConfig) => {
  if (!dateString) return ''

  const dateObj = new Date(dateString)
  const stringDate = dateObj.toLocaleString(localeConfig, {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    timeZoneName: 'short',
  })

  const modifiedDate = stringDate.replace(/,([^,]*)$/, '$1')
  if (localeConfig === 'en' && !dateString) {
    const dateParts = stringDate.split(', ')
    const timeParts = dateParts && dateParts[2].split(' ')
    const meridian = timeParts[1].toLowerCase()
    timeParts[1] = meridian
    return dateParts[0] + ', ' + dateParts[1] + ' ' + timeParts.join(' ')
  } else {
    return modifiedDate
  }
}
