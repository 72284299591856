import React from 'react'
import browserLang from 'browser-lang'
import { IntlProvider } from 'react-intl'

import { IntlContextProvider } from 'intl'
import PageComponent from './PageComponent'

const preferDefault = m => (m && m.default) || m

const withIntlProvider = intl => children => (
  <IntlProvider
    locale={intl.language}
    defaultLocale={intl.defaultLanguage}
    messages={intl.messages}
  >
    <IntlContextProvider value={intl}>{children}</IntlContextProvider>
  </IntlProvider>
)

const wrapPage = ({ element, props }) => {
  if (!props) return

  const {
    pageContext: { intl, isClientPath },
    location: { pathname, hash, search },
  } = props

  const { language, languages, redirect, pathPrefix } = intl

  let detected

  if (typeof window !== 'undefined') {
    detected =
      window.localStorage.getItem('gatsby-intl-language') ||
      browserLang({
        languages,
        fallback: language,
      })

    if (!languages.includes(detected)) {
      detected = language
    }

    window.___gatsbyIntl = intl
  }

  if (isClientPath) {
    const pathParts = pathname
      .split('/')
      .filter(str => str !== null && str !== '')

    const routed = languages.includes(pathParts[0])
    const isRedirect = redirect && !routed && detected !== 'en'

    if (isRedirect) {
      if (typeof window !== 'undefined') {
        const regExPath = pathPrefix.replace(/\//g, '')
        const hasPrefix =
          pathParts && pathParts.length > 1 && pathParts.includes(regExPath)

        const pagePath = pathParts.filter(str => str !== regExPath).join('/')
        const noPrefixPath =
          pathParts.length > 1 ? pathParts.join('/') : pathParts[0]
        const path = hasPrefix ? pagePath : noPrefixPath || regExPath

        const newUrl = hasPrefix
          ? `/${detected}${pathPrefix}/${path}/${hash}${search}`
          : `/${detected}/${path}/${hash}${search}`

        window.localStorage.setItem('gatsby-intl-language', detected)
        window.location.replace(newUrl)
      }
    }

    const renderElement = isRedirect
    ? // eslint-disable-next-line
      GATSBY_INTL_REDIRECT_COMPONENT_PATH &&
      // eslint-disable-next-line
      createElement(preferDefault(require(GATSBY_INTL_REDIRECT_COMPONENT_PATH)))
    : element

    return withIntlProvider(intl)(
      <PageComponent pathname={pathname} element={renderElement} />
    )
  } else {
    const { routed, originalPath } = intl

    const isRedirect = redirect && !routed && detected !== 'en'

    if (isRedirect) {
      if (typeof window !== 'undefined') {
        const newUrl = `/${detected}${pathPrefix}${originalPath}${hash}${search}`

        window.localStorage.setItem('gatsby-intl-language', detected)
        window.location.replace(newUrl)
      }
    }

    const renderElement = isRedirect
    ? // eslint-disable-next-line
      GATSBY_INTL_REDIRECT_COMPONENT_PATH &&
      // eslint-disable-next-line
      createElement(preferDefault(require(GATSBY_INTL_REDIRECT_COMPONENT_PATH)))
    : element

    return withIntlProvider(intl)(
      <PageComponent pathname={pathname} element={renderElement} />
    )
  }
}

export default wrapPage