import React from 'react'

import Link from 'components/link'

export const renderLink = {
  link: props => {
    let url
    switch (props.href) {
      case 'entity':
        url = `https://sites.sonypictures.com/corp/privacypolicies/b2c/sony-entities.html`
        break
      case 'subs':
        url = `https://www.sony.com/en/SonyInfo/CorporateInfo/Subsidiaries/`
        break
      case 'contact':
        url = `https://sites.sonypictures.com/corp/privacypolicies/b2c/contact-us.html`
        break

      case 'daa':
        url = `https://optout.aboutads.info/#!/`
        break
      case 'na':
        url = `https://optout.networkadvertising.org/#/`
        break
      case 'ca':
        url = `https://youradchoices.ca/choices`
        break
      case 'eu':
        url = `https://www.youronlinechoices.eu/`
        break
      case 'adobe':
        url = `https://www.adobe.com/privacy/opt-out.html`
        break
      case 'google':
        url = `https://tools.google.com/dlpage/gaoptout`
        break
      case 'flash':
        url = `http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager06.html`
        break

      default:
        url = props.href
        break
    }

    if (props.href === 'consent') {
      return (
        <button
          className="consent-link"
          onClick={() => window.Optanon.ToggleInfoDisplay()}
          onMouseDown={e => e.preventDefault()}
        >
          {props.children}
        </button>
      )
    }

    let internal = /^\/(?!\/)/.test(url)
    if (url.startsWith('#')) internal = true

    const target = !internal ? '_blank' : ''
    const rel = !internal ? 'noreferrer noopener' : ''

    return (
      <Link to={url} target={target} rel={rel}>
        {props.children}
      </Link>
    )
  },
}
